import React, { FC } from "react";
import { graphql } from "gatsby";
import Post from "../components/Post";

const post: FC<{ data: { prismicPost: PostInterface } }> = ({
  data: { prismicPost }
}) => {
  return <Post post={prismicPost} />;
};

export default post;

export const postQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        images {
          image {
            url
            alt
            dimensions {
              height
              width
            }
          }
        }
        posttext {
          text
        }
      }
    }
  }
`;
